/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The request body for exporting people
 */
export type ExportPeopleRequest = {
    /**
     * The excel configuration ID
     */
    excel_configuration_id?: string;
    /**
     * The group
     */
    group?: ExportPeopleRequest.group;
    /**
     * The tags
     */
    tags?: Array<string>;
    /**
     * The order by field
     */
    order_by?: string;
    /**
     * Get all except the following ids
     */
    uncheckedPeople?: Array<number>;
    /**
     * Get all ids that are selected
     */
    checkedPeople?: Array<number>;
    /**
     * The all selected field
     */
    allSelected?: boolean;
    /**
     * The filters
     */
    filters?: string | null;
};

export namespace ExportPeopleRequest {

    /**
     * The group
     */
    export enum group {
        INDIVIDUALS = 'individuals',
        ENTIRE_FAMILY = 'entire_family',
    }


}


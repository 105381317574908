<script lang="ts" setup>
import { computed, provide, ref, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { omit } from 'lodash'
import Auth from '@/layouts/Auth.vue'
import Blank from '@/layouts/Blank.vue'
import Default from '@/layouts/Default.vue'
import { isPublicSubdomain } from '@/functions/utils'

const route = useRoute()
const layout = shallowRef<typeof Auth | typeof Blank | typeof Default>(Blank)

interface Meta {
    layout?: string
    isVue?: boolean
    sideNav?: string
}

const meta = ref<Meta>({})

export type UpdateMeta = (metaArgs: Meta) => void

const updateMeta = (metaArgs: Meta) => {
    meta.value = metaArgs
}

const layoutProps = computed(() => ({
    ...omit(route.meta, `layout`),
    ...meta.value
}))

watch(route, () => {
    switch (route.meta.layout) {
        case `auth`:
            layout.value = Auth
            break
        case `blank`:
            layout.value = Blank
            break
        case `default`:
        default:
            layout.value = isPublicSubdomain() ? Blank : Default
            break
    }
})

provide(`updateMeta`, updateMeta)
</script>

<template>
    <component
        :is="layout"
        v-bind="layoutProps">
        <RouterView />
    </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { map } from 'lodash'
import useSupport from '@/store/support'
import BSelect from '@/components/BSelect.vue'
import BIcon from '@/components/BIcon.vue'
import type { User } from '@/types/support'

const support = useSupport()

const currentUser = computed(() => support.supportUser)
const show = computed(() => Boolean(support.supportUser))
const options = computed(() =>
    map(support.users, (user: User) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.username})`
    }))
)

const input = (event: Event) => {
    support.impersonate((event.target as HTMLSelectElement).value)
}
</script>

<template>
    <div
        v-if="show"
        class="sticky top-0 flex items-center gap-2 bg-primary !p-2">
        <BIcon
            icon="user"
            class="h-4 w-4 text-white" />

        <BSelect
            :model-value="currentUser"
            :options="options"
            block
            class="!h-6 shrink border-white !bg-primary !py-0 text-sm text-white focus:border-white focus:shadow-none"
            @input="input" />
    </div>
</template>

import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { plainToInstance, instanceToPlain } from 'class-transformer'
import useApi from '@/composables/api'
import { QUERY_KEYS } from '@/constants/query'
import { map } from 'lodash'
import UserResource from '@/services/api/transformers/UserResource'
import type { SerializedUserResource } from '@/services/api/transformers/UserResource'
import { ref } from 'vue'
import type { UserBaseRequest } from '@/services/api/client/models/UserBaseRequest'
import type { SerializedUserRequest } from '@/services/api/transformers/UserUpdate'
import type { Filters } from '@/utils/queries'

const { api } = useApi()

// Define a type for the query parameters
interface QueryParams {
    sort?: string
    filter?: Filters
}

interface UpdatePayload {
    id: number
    payload: SerializedUserRequest
}

// Create a function to generate the query parameters object
const createQueryParams = (sort?: string, filter?: Filters): QueryParams => {
    return { ...(sort !== undefined && { sort }), ...(filter !== undefined && { filter }) }
}

const useUsers = (sort: string | undefined = undefined, filter: Filters | undefined = undefined) => {
    const queryParams = ref(createQueryParams(sort, filter))

    return useQuery({
        queryKey: QUERY_KEYS.users.list(queryParams),
        queryFn: () => api.users.getUsers({ sort, filter }),
        select: ({ data }) => map(data, user => plainToInstance(UserResource, user))
    })
}

const useUser = (id: number) => {
    const userId = ref(id)
    const { api } = useApi()

    return useQuery({
        queryKey: QUERY_KEYS.users.detail(userId),
        queryFn: () => api.users.getUser({ id }),
        select: ({ data }) => map(data, user => plainToInstance(UserResource, user))
    })
}

const useUserCreate = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: UserBaseRequest) =>
            api.users.createUser({
                requestBody: instanceToPlain(payload) as SerializedUserResource
            }),
        onSuccess: () => queryClient.refetchQueries({ queryKey: QUERY_KEYS.users.all }),
        onError: () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.users.all })
    })
}

const useUserUpdate = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id, payload }: UpdatePayload) =>
            api.users.updateUser({
                id,
                requestBody: instanceToPlain(payload) as SerializedUserRequest
            }),
        onSuccess: () => queryClient.refetchQueries({ queryKey: QUERY_KEYS.users.all }),
        onError: () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.users.all })
    })
}

const useUserDelete = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: number) => api.users.deleteUser({ id }),
        onSuccess: () => queryClient.refetchQueries({ queryKey: QUERY_KEYS.users.all }),
        onError: () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.users.all })
    })
}

export { useUsers, useUser, useUserCreate, useUserUpdate, useUserDelete }

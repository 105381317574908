import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { includes, map, replace } from 'lodash'
import useEventBus from '@/composables/eventBus'
import queryClient from '@/plugins/query'
import { useMe } from '@/queries/me'
import { QUERY_KEYS } from '@/constants/query'
import NAV_MAIN from '@/constants/nav/main'
import type { NavMainItem } from '@/types/nav/main'
import { filterNavPermissions } from '@/functions/nav'

const useNavMain = () => {
    const { data: me, hasGiving, hasBreezeBundle } = useMe()
    const { emit, on } = useEventBus()
    const { path } = useRoute()

    const linkedPersonId = computed(() => me.value?.linkedPersonId ?? null)
    const features = computed(() => me.value?.features ?? [])
    const roles = computed(() => me.value?.roles ?? [])

    on(`*`, async (type: string) => {
        if (includes([`clearNav`, `refreshNav`], type)) {
            await queryClient.refetchQueries({ queryKey: QUERY_KEYS.me, exact: true })
        }

        if (type === `clearNav`) {
            emit(`cacheCleared`)
        }

        if (type === `clearGroupsDetails`) {
            await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.groups.details() })
        }
    })

    const mapItems = (item: NavMainItem): NavMainItem => {
        if (item.route?.includes(`{linked_person_id}`)) {
            if (linkedPersonId.value !== null) {
                item.route = replace(item.route, `{linked_person_id}`, linkedPersonId.value.toString())
            } else {
                // Empty routes are filtered out
                item.route = ``
            }
        }

        if (hasBreezeBundle.value && item.key === `giving`) {
            item.route = `#tithely-giving`
            item.action = `tithely-giving`
        }

        return item
    }

    const nav = computed(() => {
        if (path === `/r/suspended`) {
            return []
        }

        const items = map(NAV_MAIN, mapItems)

        return filterNavPermissions(items, features, roles, hasGiving.value)
    })

    return { nav }
}

export default useNavMain

import { reactive } from 'vue'
import { forEach } from 'lodash'
import { useCookies } from '@vueuse/integrations/useCookies'
import { stringify } from 'qs'
import axios from '@/services/api/wrapper/axiosInstance'
import ENV from '@/constants/env'
import { CACHE_KEY } from '@/constants/query'
import { ApiClient } from '@/services/api/client'
import { AxiosHttpWrapper } from '@/services/api/wrapper/HttpRequestWrapper'
import type { Api, CancelKey, Options } from '@/types/composables/api'
import { COOKIE_OPTIONS } from '@/constants/cookies'
import type { OpenAPIConfig } from '@/services/api/client/core/OpenAPI'
import type { AxiosError, AxiosResponse } from 'axios'

const apiHost = ENV.apiHost

const useApi = (config?: Partial<OpenAPIConfig>): Api => {
    const api = new ApiClient(
        {
            BASE: apiHost,
            ...config
        },
        AxiosHttpWrapper
    )

    const controllers = reactive<Record<string, AbortController>>({})

    const cookies = useCookies()

    const request = (method: string, url: string, data?: unknown, options?: Options, legacy = false) => {
        if (options?.cancel) {
            controllers[options.cancel] = new AbortController()
        }

        return axios({
            method,
            url: `${legacy ? window.origin : apiHost}${url}`,
            headers: {
                ...(options?.headers ? options.headers : {})
            },
            ...(data ? { data } : {}),
            ...(options?.cancel
                ? {
                      signal: controllers[options.cancel].signal
                  }
                : {}),
            ...(options?.config ? options.config : {}),
            // let Axios automagically figure out content type for legacy endpoints
            ...(legacy && !options?.headers?.['Content-Type']
                ? {
                      transformRequest: [
                          (data, headers) => {
                              headers['Content-Type'] = null
                              return data
                          }
                      ]
                  }
                : {})
        })
            .catch((error: AxiosError) => {
                switch (error?.message) {
                    case `Request aborted`:
                    case `canceled`:
                        return Promise.resolve({})
                }

                return Promise.reject(error)
            })
            .then((response: AxiosResponse | Record<string, never>) => Promise.resolve(response.data))
    }

    const cancelCall = (cancelKey: CancelKey) => {
        if (controllers[cancelKey]) {
            controllers[cancelKey].abort()
        }
    }

    const logout = (redirect?: string) => {
        forEach(controllers, cancelKey => {
            cancelKey.abort()
        })

        window.echo?.leaveAllChannels()

        cookies.remove(`access_token`, COOKIE_OPTIONS)
        cookies.remove(`session_key`, COOKIE_OPTIONS)

        localStorage.removeItem(CACHE_KEY)

        window.open(`/login/logout${redirect ? `?${stringify({ continue: redirect })}` : ``}`, `_self`)
    }

    const call = (method: string, url: string, payload?: unknown, options?: Options) => {
        if (options?.cancel && controllers[options.cancel]) {
            controllers[options.cancel].abort()
        }

        return request(method, url, payload, options).finally(() => {
            if (options?.cancel && controllers[options?.cancel]) {
                delete controllers[options?.cancel]
            }
        })
    }

    const callLegacy = (method: string, url: string, payload?: unknown, options?: Options) => {
        if (options?.cancel && controllers[options.cancel]) {
            controllers[options.cancel].abort()
        }

        return request(method, url, payload, options, true).finally(() => {
            if (options?.cancel && controllers[options?.cancel]) {
                delete controllers[options?.cancel]
            }
        })
    }

    /**
     * Bind methods to window to allow direct access from Zephyr
     */
    const init = () => {
        window.apiCall = call
        window.apiCancel = cancelCall
        window.apiReady = true
    }

    return {
        init,
        logout,
        call,
        callLegacy,
        cancelCall,
        api
    }
}

export default useApi

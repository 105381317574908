import { Expose, instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import type { PersonDetailResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'
import { map } from 'lodash'
import PeopleDetailProfileFieldResource from '@/services/api/transformers/PeopleDetailProfileFieldResource'
import PeopleDetailPersonDetailsResource from '@/services/api/transformers/PeopleDetailPersonDetailsResource'
import PeopleDetailFamilyMemberResource from '@/services/api/transformers/PeopleDetailFamilyMemberResource'

export type SerializedPersonDetailResource = Model

export default class PersonDetailResource implements CamelCaseKeys<SerializedPersonDetailResource> {
    id: Model['id']

    @Expose({ name: 'person_details' })
    @Transform(({ value }) => plainToInstance(PeopleDetailPersonDetailsResource, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    personDetails?: PeopleDetailPersonDetailsResource

    @Expose({ name: 'is_linked' })
    isLinked?: Model['is_linked']

    @Expose({ name: 'profile_fields' })
    @Transform(({ value }) => map(value, item => plainToInstance(PeopleDetailProfileFieldResource, item)), {
        toClassOnly: true
    })
    @Transform(({ value }) => map(value, item => instanceToPlain(item)), { toPlainOnly: true })
    profileFields?: PeopleDetailProfileFieldResource[]

    @Expose({ name: 'family_members' })
    @Transform(({ value }) => map(value, item => plainToInstance(PeopleDetailFamilyMemberResource, item)), {
        toClassOnly: true
    })
    @Transform(({ value }) => map(value, item => instanceToPlain(item)), { toPlainOnly: true })
    familyMembers?: PeopleDetailFamilyMemberResource[]

    constructor(values: PersonDetailResource) {
        Object.assign(this, values)
    }
}

import { computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { filter, some } from 'lodash'
import { plainToInstance } from 'class-transformer'
import useApi from '@/composables/api'
import { QUERY_KEYS } from '@/constants/query'
import IntegrationResource from '@/services/api/transformers/IntegrationResource'
import { useMe } from '@/queries/me'

const useExtensions = () => {
    const queryKey = QUERY_KEYS.extensions
    const queryClient = useQueryClient()
    const { api } = useApi()
    const { hasGiving } = useMe()

    const {
        data,
        isLoading: isLoadingAll,
        refetch
    } = useQuery({
        queryKey,
        queryFn: () => api.integrations.getIntegrations().then(({ data }) => data),
        select: data => plainToInstance(IntegrationResource, data)
    })

    const hasExtension = (key: string) => some(data.value, { name: key }) ?? false

    const { mutateAsync: enable, isPending: isLoadingEnable } = useMutation({
        mutationKey: queryKey,
        mutationFn: ({ id }: { id: number }) => api.integrations.runIntegrationAction({ id, action: 'enable' }),
        onSuccess: async () => {
            await refetch()
            await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me })
            await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.extensions })
        }
    })

    const { mutateAsync: action, isPending: isLoadingAction } = useMutation({
        mutationKey: queryKey,
        mutationFn: ({
            id,
            action,
            requestBody
        }: {
            id: number
            action: string
            requestBody?: Record<string, unknown>
        }) => api.integrations.runIntegrationAction({ id, action, requestBody }),
        onSuccess: () => refetch()
    })

    const { mutateAsync: disable, isPending: isLoadingDisable } = useMutation({
        mutationKey: queryKey,
        mutationFn: ({ id }: { id: number }) => api.integrations.runIntegrationAction({ id, action: 'disable' }),
        onSuccess: async () => {
            await refetch()
            await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me })
            await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.extensions })
        }
    })

    const isLoading = computed(
        () => isLoadingAll.value || isLoadingEnable.value || isLoadingDisable.value || isLoadingAction.value
    )

    const filteredExtensions = computed(() => {
        if (hasGiving.value) {
            return filter(data.value, extension => extension.name !== 'church_apps_integrations')
        }
        return data.value
    })

    return { data, filteredExtensions, action, enable, disable, hasExtension, isLoading }
}

export default useExtensions

import type { NavMainItem } from '@/types/nav/main'

const NAV_MAIN: NavMainItem[] = [
    {
        label: `Dashboard`,
        route: `/dashboard`
    },
    {
        label: `People`,
        route: `/people`,
        roles: [`people`]
    },
    {
        label: `Giving`,
        key: `giving`,
        route: `/payments`,
        roles: [`contributions`]
    },
    {
        label: `Tags`,
        route: `/r/tags`,
        roles: [`tags`]
    },
    {
        label: `Events`,
        route: `/r/events`,
        roles: [`events_view`]
    },
    {
        label: `Forms`,
        route: `/forms`,
        roles: [`forms`]
    },
    {
        label: `Follow Ups`,
        route: `/followups`,
        roles: [`followups`]
    },
    {
        label: `Groups`,
        route: `/groups`,
        features: [`breeze-groups-admin`, `church_apps_integration`],
        localFeatures: [`breeze-groups-admin`],
        roles: [`groups_edit`, `groups_leader`],
        orRoles: true,
        isTithelyGivingEnabled: false // Remove for Apps Phase 2
    },
    {
        // Upsell Groups
        label: `Groups`,
        route: `/extensions?name=church_apps_integration`,
        roles: ['marketplace'],
        features: [`tithely-bundle`, `!church_apps_integration`, `breeze-groups-admin`],
        badge: `Upgrade`,
        secondary: true,
        isTithelyGivingEnabled: false // Remove for Apps Phase 2
    },
    {
        label: 'MailChimp',
        route: `/mailchimp`,
        roles: ['extensions_mailchimp'],
        secondary: true
    },
    {
        label: `Planning Center Online`,
        route: `/pco`,
        roles: ['extensions_pco'],
        secondary: true
    },
    {
        label: `QuickBooks Desktop`,
        route: `/quickbooks`,
        roles: ['extensions_quickbooks'],
        secondary: true
    },
    {
        // Normal Sites
        label: `Sites`,
        route: `/account/tithely/sso?redirect=/sites`,
        roles: ['extensions_sites'],
        features: ['sites_integration']
    },
    {
        // Upsell Sites
        label: `Sites`,
        route: `/extensions?name=sites_integration`,
        roles: ['marketplace'],
        features: [`tithely-bundle`, `!sites_integration`],
        badge: `Upgrade`
    },
    {
        // Normal Apps
        label: `Apps`,
        route: `/account/tithely/sso?redirect=/apps`,
        roles: ['extensions_church_apps'],
        features: ['church_apps_integration'],
        secondary: true,
        isTithelyGivingEnabled: false // Remove for Apps Phase 2
    },
    {
        // Upsell Apps
        label: `Apps`,
        route: `/extensions?name=church_apps_integration`,
        roles: ['marketplace'],
        features: [`tithely-bundle`, `!church_apps_integration`],
        badge: `Upgrade`,
        secondary: true,
        isTithelyGivingEnabled: false // Remove for Apps Phase 2
    },
    {
        label: `Extensions`,
        icon: `extensions`,
        route: `/extensions`,
        roles: [`marketplace`],
        secondary: true
    },
    {
        label: `My Profile`,
        route: `/people/view/{linked_person_id}`,
        roles: [`show_my_profile`]
    },
    {
        label: `Give Now`,
        route: `/give/internal`,
        roles: [`linked_people_give`],
        features: [`!tithely-giving-30`]
    }
]

export default NAV_MAIN

import 'vite/modulepreload-polyfill'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createVfm } from 'vue-final-modal'
import Toast, { POSITION } from 'vue-toastification'
import * as Sentry from '@sentry/vue'
import { VTooltip } from 'floating-vue'
import { vOnClickOutside } from '@vueuse/components'
import { createI18n } from 'vue-i18n'
import { createGtm } from '@gtm-support/vue-gtm'
import { SproutKitPlugin } from '@tithely/sproutkit-vue'
import router from '@/plugins/router'
import { vueQueryOptions } from '@/plugins/query'
import App from '@/App.vue'
import ENV from '@/constants/env'
import 'floating-vue/dist/style.css'
import 'vue-final-modal/style.css'
import '@/assets/fonts'
import '@/assets/style/style.scss'
import '@/assets/style/toast.scss'
import '@/assets/style/trinity.scss'
import messages from '@/lang'
// reflect-metadata required for class-transformer (https://github.com/typestack/class-transformer#nodejs)
import 'reflect-metadata'

const app = createApp(App)
const vfm = createVfm()

if (ENV.environment === `qa`) {
    // eslint-disable-next-line no-console
    console.log(`I AM QA`)
}

if (ENV.sentryDSN) {
    Sentry.init({
        app,
        dsn: ENV.sentryDSN,
        environment: ENV.environment,
        ...(ENV.sentryReplay === `enabled`
            ? {
                  integrations: [Sentry.replayIntegration()],
                  replaysOnErrorSampleRate: 0.01
              }
            : {})
    })
}

// Plugins
app.use(SproutKitPlugin)

app.use(
    createI18n({
        legacy: false,
        locale: 'en',
        fallbackLocale: 'en',
        messages
    })
)
app.use(createPinia())
app.use(createHead())
app.use(router)
app.use(VueQueryPlugin, vueQueryOptions)
app.use(vfm)
app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    hideProgressBar: true
})
// Google Tag Manager
if (ENV.googleTagManager) {
    app.use(
        createGtm({
            id: ENV.googleTagManager,
            queryParams: {
                gtm_auth: '',
                gtm_preview: '',
                gtm_cookies_win: 'x'
            },
            vueRouter: router
        })
    )
}

// Directives
app.directive(`tooltip`, VTooltip)
app.directive(`click-out`, vOnClickOutside)

// Load App
app.mount(`#app`)

import { type Constructor, useModal } from 'vue-final-modal'
import UserSettings from '@/components/modals/UserSettings.vue'
import UserPassword from '@/components/modals/UserPassword.vue'
import UserNotifications from '@/components/modals/UserNotifications.vue'
import UserReferral from '@/components/modals/UserReferral.vue'

export const modalParamMap = {
    'user-settings': 'launchUserSettings',
    'change-password': 'launchChangePassword',
    'notifications': 'launchNotifications',
    'referrals': 'launchReferral',
    'song-library': 'launchSongLibrary'
} as const

export const useModalTriggers = () => {
    const userSettingsModal = useModal({ component: UserSettings as Constructor })
    const userPasswordModal = useModal({ component: UserPassword as Constructor })
    const userNotificationsModal = useModal({ component: UserNotifications as Constructor })
    const userReferralModal = useModal({ component: UserReferral as Constructor })

    const handleModalAction = (action: string) => {
        switch (action) {
            case 'launchUserSettings':
                userSettingsModal.open()
                break
            case 'launchChangePassword':
                userPasswordModal.open()
                break
            case 'launchNotifications':
                userNotificationsModal.open()
                break
            case 'launchReferral':
                userReferralModal.open()
                break
        }
    }

    const handleUrlModalTrigger = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const modalParam = urlParams.get(`modal`) as keyof typeof modalParamMap | null

        if (modalParam && modalParam in modalParamMap) {
            handleModalAction(modalParamMap[modalParam])

            urlParams.delete(`modal`)

            window.history.replaceState(
                {},
                '',
                `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`
            )
        }
    }

    return {
        userSettingsModal,
        userPasswordModal,
        userNotificationsModal,
        userReferralModal,
        handleModalAction,
        handleUrlModalTrigger
    }
}

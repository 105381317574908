<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { some, startsWith } from 'lodash'
import { useRoute } from 'vue-router'
import utils from '@/plugins/utils'
import toast from '@/plugins/toast'
import useApi from '@/composables/api'
import GetLayout from '@/layouts/GetLayout.vue'
import GtmProfileSync from '@/components/GtmProfileSync.vue'
import { PUBLIC_ROUTES } from '@/constants/routes'
import { isPublicSubdomain } from '@/functions/utils'

const { init } = useApi()
const route = useRoute()

const mountGtm = computed(
    () => !some(PUBLIC_ROUTES, path => startsWith(route.path, path)) && route.path !== `/` && !isPublicSubdomain()
)

onBeforeMount(() => {
    utils()
    toast()
    init()
})
</script>

<template>
    <GtmProfileSync v-if="mountGtm" />

    <GetLayout />

    <ModalsContainer />
</template>

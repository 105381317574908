<script setup lang="ts">
import { computed, type ExtractPropTypes } from 'vue'
import { useVfm, VueFinalModal } from 'vue-final-modal'
import { v4 as uuid } from 'uuid'
import { omit } from 'lodash'
import { cva } from 'class-variance-authority'

defineOptions({ inheritAttrs: false })

const vfm = useVfm()

interface Props extends /* @vue-ignore */ ExtractPropTypes<typeof VueFinalModal> {
    modalId?: string
    size?: `sm` | `md` | `lg` | `full` | `custom`
    class?: string[] | string
}

const props = withDefaults(defineProps<Props>(), {
    modalId: () => uuid(),
    size: `sm`,
    class: ``
})

const open = () => {
    vfm.open(props.modalId)
}

const close = () => {
    vfm.close(props.modalId)
}

const closeAll = () => {
    vfm.closeAll()
}

const contentClasses = cva(`my-auto w-full rounded-lg bg-white shadow outline-none`, {
    variants: {
        size: {
            sm: `max-w-[20rem]`,
            md: `max-w-[40rem]`,
            lg: `max-w-[60rem]`,
            full: `max-w-none`,
            custom: ``
        }
    }
})

const modalProps = computed(() => ({
    ...omit(props, [`size`]),
    class: `horizon-ui`,
    contentClass: [props.class, contentClasses({ size: props.size })],
    overlayClass: `backdrop-blur-[2px] fixed`,
    overlayTransition: {
        enterActiveClass: `transition-opacity duration-200`,
        leaveActiveClass: `transition-opacity duration-200`,
        enterFromClass: `opacity-0`,
        leaveToClass: `opacity-0`
    },
    contentTransition: {
        enterActiveClass: `transition-all duration-300`,
        leaveActiveClass: `transition-all duration-300`,
        enterFromClass: `opacity-0 -translate-y-8`,
        leaveToClass: `opacity-0 translate-y-8`
    },
    style: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        overflowY: `auto`
    }
}))

defineExpose({ open, close, closeAll })
</script>

<template>
    <VueFinalModal v-bind="{ ...modalProps, ...$attrs }">
        <slot />
    </VueFinalModal>
</template>

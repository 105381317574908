/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportPeopleRequest } from '../models/ExportPeopleRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PusherService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Generate and download an invoice
     * @returns binary Invoice generated and downloaded
     * @throws ApiError
     */
    public exportInvoice({
        exportKey,
        invoice,
    }: {
        exportKey: string,
        invoice: string,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pusher/invoice/{exportKey}',
            path: {
                'exportKey': exportKey,
            },
            query: {
                'invoice': invoice,
            },
            errors: {
                403: `User does not have Export permission`,
            },
        });
    }

    /**
     * Export mailing labels
     * @returns any Mailing labels export initiated
     * @throws ApiError
     */
    public exportLabels({
        exportKey,
        requestBody,
    }: {
        exportKey: string,
        requestBody: ExportPeopleRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pusher/mailing-labels/{exportKey}',
            path: {
                'exportKey': exportKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `User does not have Export permission`,
            },
        });
    }

    /**
     * Export people data
     * @returns any People data export initiated
     * @throws ApiError
     */
    public exportPeople({
        exportKey,
        requestBody,
    }: {
        exportKey: string,
        requestBody: ExportPeopleRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pusher/people/{exportKey}',
            path: {
                'exportKey': exportKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `User does not have Export permission`,
            },
        });
    }

}

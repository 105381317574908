import groups from '@/constants/queryKeys/groups'
import tags from '@/constants/queryKeys/tags'
import users from '@/constants/queryKeys/users'
import roles from '@/constants/queryKeys/roles'
import userFields from '@/constants/queryKeys/userFields'
import preferences from '@/constants/queryKeys/preferences'
import exportExcelConfig from '@/constants/queryKeys/exportExcelConfig'
import taggedPeople from '@/constants/queryKeys/taggedPeople'

export default {
    preferences,
    tags,
    userFields,
    users,
    roles,
    groups,
    exportExcelConfig,
    taggedPeople
}

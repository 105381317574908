import axios, { type AxiosRequestConfig } from 'axios'
import { useCookies } from '@vueuse/integrations/useCookies'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { get, includes } from 'lodash'
import axiosRetry from 'axios-retry'
import ENV from '@/constants/env'
import useApi from '@/composables/api'
import { COOKIE_OPTIONS } from '@/constants/cookies'
import { isPublicSubdomain } from '@/functions/utils'
import type { LoginResponse } from '../client'

interface FailedRequest {
    response: {
        config: AxiosRequestConfig
    }
}

const apiHost = ENV.apiHost
const cookies = useCookies()

const axiosInstance = axios.create({
    baseURL: apiHost,
    headers: { 'Content-Type': 'application/json' }
})

axiosRetry(axiosInstance, { retries: 2, retryCondition: error => error.response?.status === 502 })

axiosInstance.interceptors.request.use(config => {
    const token = cookies.get(`access_token`)

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const { logout } = useApi()

        if (includes([417, 418], error.response?.status)) {
            logout()
        }

        return Promise.reject(error)
    }
)

const refreshAuthLogic = (failedRequest: FailedRequest): Promise<void> => {
    const { logout } = useApi()

    if (isPublicSubdomain()) {
        return Promise.reject()
    }

    const accessToken = cookies.get(`access_token`)
    const sessionKey = cookies.get(`session_key`)

    if (!accessToken && !sessionKey) {
        logout()
        return Promise.reject()
    }

    return axios(
        accessToken
            ? {
                  method: `POST`,
                  url: `${apiHost}/auth/refresh`,
                  headers: {
                      Authorization: `Bearer ${accessToken}`
                  }
              }
            : {
                  method: `POST`,
                  url: `${apiHost}/auth/sessionkey`,
                  data: {
                      session_key: sessionKey
                  }
              }
    )
        .then((response: { data: LoginResponse }) => {
            const newAccessToken = get(response, `data.access_token`)

            if (!newAccessToken) {
                return Promise.reject()
            }

            cookies.set(`access_token`, newAccessToken, COOKIE_OPTIONS)
            if (failedRequest.response.config.headers) {
                failedRequest.response.config.headers['Authorization'] = `Bearer ${newAccessToken}`
            }
            return Promise.resolve()
        })
        .catch(() => {
            // Could not get a new access token, so logout with redirect
            logout(`${window.location.pathname}${window.location.search}`)
            return Promise.reject()
        })
}

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic)

export default axiosInstance

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useForm } from 'vee-validate'
import { object, ref as yupRef, string } from 'yup'
import { every, includes } from 'lodash'
import { SpButton, SpInput, SpLabel, SpIconField } from '@tithely/sproutkit-vue'
import { PhEye, PhEyeClosed } from '@phosphor-icons/vue'
import { useMeUpdate } from '@/queries/me'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import FormError from '@/components/FormError.vue'
import { usePasswordRequirements } from '@/composables/usePasswordRequirements'
import PasswordRequirements from '@/components/PasswordRequirements.vue'
import { useUserUpdate } from '@/queries/users/users'
import type UserResource from '@/services/api/transformers/UserResource'

const modal = ref<InstanceType<typeof BaseModal>>()

const { mutate: updateMe, status } = useMeUpdate()
const { mutateAsync: updateUser, isPending: pending } = useUserUpdate()
const toast = useToast()
const userId = ref<number | null>(null)
const selectedUser = ref<UserResource | null>(null)
const title = computed(() =>
    !selectedUser.value ? `Update User Password` : `Update ${selectedUser.value?.username} Password`
)

const { defineField, errors, handleSubmit, setFieldError } = useForm({
    initialValues: {
        currentPassword: ``,
        password: ``,
        passwordConfirmation: ``
    },
    validationSchema: object().shape({
        currentPassword: string().required(`Current Password is required`),
        password: string().required(`Password is required`),
        passwordConfirmation: string()
            .required(`Confirmation Password is required`)
            .oneOf([yupRef(`password`)], `Passwords do not match`)
    })
})

const [currentPassword, currentPasswordAttrs] = defineField(`currentPassword`)
const [password, passwordAttrs] = defineField(`password`)
const [passwordConfirmation, passwordConfirmationAttrs] = defineField(`passwordConfirmation`)

const isLoading = computed(() => status.value === `pending` || pending.value)

const close = () => {
    modal.value?.close()
}

const open = (user: UserResource) => {
    selectedUser.value = user
    if (user.id !== undefined) {
        userId.value = user.id
    }
    modal.value?.open()
}

defineExpose({
    open,
    close
})

const updateSelectedUser = (requestBody: {
    current_password: string
    password: string
    password_confirmation: string
}) => {
    if (!userId.value) {
        return
    }

    updateUser(
        { id: userId.value, payload: requestBody },
        {
            onSuccess: () => {
                close()
                toast.success(`User ${selectedUser.value?.username}'s password has been updated`)
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                if (error?.body?.error) {
                    if (includes(error.body.error, `current password`)) {
                        setFieldError(`password`, error.body.error)
                    } else {
                        toast.error(error.body.error)
                    }
                }
            }
        }
    )
}

const updateCurrentUser = (requestBody: {
    current_password: string
    password: string
    password_confirmation: string
}) => {
    updateMe(requestBody, {
        onSuccess: () => {
            close()
            toast.success(`Password was updated successfully`)
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
            if (error?.body?.error) {
                if (includes(error.body.error, `current password`)) {
                    setFieldError(`password`, error.body.error)
                } else {
                    toast.error(error.body.error)
                }
            }
        }
    })
}

const submit = handleSubmit(values => {
    const requestBody = {
        current_password: values.currentPassword,
        password: values.password,
        password_confirmation: values.passwordConfirmation
    }

    if (userId.value) {
        updateSelectedUser(requestBody)
    } else {
        updateCurrentUser(requestBody)
    }
})

const showCurrentPassword = ref(false)
const showPassword = ref(false)

const { passwordRequirements } = usePasswordRequirements(password)

const canSubmit = computed(
    () =>
        every(passwordRequirements.value, requirement => requirement.passed) &&
        currentPassword.value.length > 0 &&
        password.value === passwordConfirmation.value
)
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="flex flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">{{ title }}</h2>
        </BaseModalHeader>

        <form class="!m-0 grid grid-cols-1 gap-4">
            <div class="flex flex-col gap-2">
                <SpLabel required>Current Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="currentPassword"
                        v-bind="currentPasswordAttrs"
                        :type="showCurrentPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showCurrentPassword = !showCurrentPassword">
                            <component
                                :is="showCurrentPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.currentPassword" />
            </div>

            <div class="flex flex-col gap-2">
                <SpLabel required>New Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="password"
                        v-bind="passwordAttrs"
                        :type="showPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showPassword = !showPassword">
                            <component
                                :is="showPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.password" />
            </div>

            <div class="flex flex-col gap-2">
                <SpLabel required>Confirm New Password</SpLabel>
                <SpIconField>
                    <SpInput
                        v-model="passwordConfirmation"
                        v-bind="passwordConfirmationAttrs"
                        :type="showPassword ? `text` : `password`"
                        :disabled="isLoading"
                        required
                        class="w-full" />
                    <template #end>
                        <button
                            type="button"
                            class="flex size-6 items-center justify-center rounded outline-none focus:ring-2 focus:ring-primary/50"
                            @click="showPassword = !showPassword">
                            <component
                                :is="showPassword ? PhEye : PhEyeClosed"
                                weight="bold" />
                        </button>
                    </template>
                </SpIconField>
                <FormError :error="errors.passwordConfirmation" />
                <PasswordRequirements
                    :requirements="passwordRequirements"
                    class="mt-3" />
            </div>

            <div class="flex items-center justify-end gap-4">
                <SpButton
                    intent="secondary"
                    variant="subtle"
                    :disabled="isLoading"
                    @click="close">
                    Cancel
                </SpButton>

                <SpButton
                    :loading="isLoading"
                    :disabled="!canSubmit"
                    @click="submit">
                    {{ isLoading ? `Updating` : `Update` }}
                </SpButton>
            </div>
        </form>
    </BaseModal>
</template>

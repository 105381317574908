/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentUserUpdateRequest } from '../models/CurrentUserUpdateRequest';
import type { MeResource } from '../models/MeResource';
import type { MyGroupResource } from '../models/MyGroupResource';
import type { TotpRecoveryCode } from '../models/TotpRecoveryCode';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationLoggedInUserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get logged on user's context.
     * @returns any OK
     * @throws ApiError
     */
    public getMe(): CancelablePromise<{
        data: MeResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Updates the currently logged in user.
     * Allows a user to update just their username or email address.
     * @returns any OK
     * @throws ApiError
     */
    public updateMe({
        requestBody,
    }: {
        requestBody: CurrentUserUpdateRequest,
    }): CancelablePromise<{
        data: MeResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get initial code for email MFA.
     * @returns void
     * @throws ApiError
     */
    public requestEmailMfa(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me/email-mfa',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public enableEmailMfa({
        requestBody,
    }: {
        requestBody: {
            /**
             * The 6-digit code from the user's email
             */
            code: string;
        },
    }): CancelablePromise<{
        data: {
            /**
             * The success message
             */
            message: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/me/email-mfa',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Remove Email MFA from this user.
     * @returns void
     * @throws ApiError
     */
    public disableEmailMfa(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/me/email-mfa',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Send email for Email Verification.
     * @returns void
     * @throws ApiError
     */
    public requestEmailVerification(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me/email-verification',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Conflict`,
            },
        });
    }

    /**
     * Verify email verification code.
     * @returns void
     * @throws ApiError
     */
    public verifyEmail({
        requestBody,
    }: {
        requestBody: {
            /**
             * The 6-digit code from the user's email
             */
            code: string;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/me/email-verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                409: `Conflict`,
            },
        });
    }

    /**
     * Get User's Groups.
     * User must be linked to a person.
     * @returns any OK
     * @throws ApiError
     */
    public getMyGroups(): CancelablePromise<{
        data: Array<MyGroupResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me/groups',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Endpoint for ChurchApps to link a user to a person.
     * Only available if the user is not already linked.
     * A phone number should be included to help find the person
     * and will be added if a person is created.
     * If the person_id is provided, that person will be linked
     * to the current user (if an integer) or a new person will be
     * created (if null). Do not include the person_id in the request
     * if you want to search for a person based on the phone number.
     * If an exact match is found when searching, that person will be
     * linked automatically. If no match is found, a person will be created.
     * @returns void
     * @throws ApiError
     */
    public linkToPerson({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Phone number to search for existing people or create a new person
             */
            phone?: string;
            /**
             * The ID of the person to link to the current user. Use null to create a new person.
             */
            person_id?: number | null;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/me/link',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Generate a secret key for TOTP authentication.
     * It will not be enabled until confirmed.
     * @returns any OK
     * @throws ApiError
     */
    public requestTotpmfa(): CancelablePromise<{
        data: {
            /**
             * The URI to be used in the authenticator app
             */
            uri: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me/totp-mfa',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Confirm TOTP authentication with a code.
     * @returns any OK
     * @throws ApiError
     */
    public enableTotpmfa({
        requestBody,
    }: {
        requestBody: {
            /**
             * The 6-digit code from the authenticator app
             */
            code: string;
        },
    }): CancelablePromise<{
        data: {
            /**
             * The success message
             */
            message: string;
            recovery_codes: Array<TotpRecoveryCode>;
        };
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/me/totp-mfa',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Remove TOTP MFA from this user.
     * @returns void
     * @throws ApiError
     */
    public disableTotpmfa(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/me/totp-mfa',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}

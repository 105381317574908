import { Expose } from 'class-transformer'
import type { UserResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedUserResource = Model

export default class UserResource implements CamelCaseKeys<SerializedUserResource> {
    id?: Model['id']

    username?: Model['username']

    @Expose({ name: 'first_name' })
    firstName?: Model['first_name']

    @Expose({ name: 'last_name' })
    lastName?: Model['last_name']

    email?: Model['email']
    
    @Expose({ name: 'email_verified' })
    emailVerified?: Model['email_verified']

    @Expose({ name: 'role_id' })
    roleId?: Model['role_id']

    @Expose({ name: 'is_primary' })
    isPrimary?: Model['is_primary']

    @Expose({ name: 'mfa_status' })
    mfaStatus?: Model['mfa_status']

    @Expose({ name: 'is_linked' })
    isLinked?: Model['is_linked']

    @Expose({ name: 'last_active' })
    lastActive?: Model['last_active']

    roles: Model['roles']

    constructor(values: UserResource) {
        Object.assign(this, values)
    }
}


<script lang="ts" setup>
import { computed, ref } from 'vue'
import { cva } from 'class-variance-authority'
import { Dropdown as VDropdown } from 'floating-vue'
import { concat, groupBy, reduce, slice, some } from 'lodash'
import { useElementSize } from '@vueuse/core'
import BIcon from '@/components/BIcon.vue'
import useNavMain from '@/composables/nav/main'
import type { NavMainItem } from '@/types/nav/main'
import useRouteHelper from '@/composables/routeHelper'

interface Props {
    searchWidth: number
}

const props = defineProps<Props>()

const { nav } = useNavMain()
const { isCurrentRoute } = useRouteHelper(nav.value)

// Try to fit as many menu items in the primary nav as possible
const DEFAULT_PRIMARY_LENGTH = 4
const mainMenu = ref(null)
const { width: navWidth } = useElementSize(mainMenu)
const menuWidths = ref([])

// Get number of menu items that can be safely displayed
const primaryLength = computed(() => {
    try {
        const availableWidth =
            navWidth.value +
            props.searchWidth! -
            300 - // factor in dynamic width of search bar
            90 - // hardcode space needed for secondary menu
            16 // extra spacing for good luck

        // add up pixel width of menu items that can fit in available width and return that number of items
        return reduce(
            menuWidths.value,
            ({ width, items }, item: HTMLElement) => ({
                width: width + item.getBoundingClientRect().width,
                items: width + item.getBoundingClientRect().width >= availableWidth ? items : items + 1
            }),
            { width: 0, items: 0 }
        ).items
    } catch {
        return DEFAULT_PRIMARY_LENGTH
    }
})

// Split menu items that are always supposed to live under secondary
const getNavGrouped = computed(() => {
    const { false: primary = [], true: secondary = [] } = groupBy(nav.value, { secondary: true })
    return { primary, secondary }
})

const getPrimaryNav = computed<NavMainItem[]>(() => slice(getNavGrouped.value.primary, 0, primaryLength.value))
// Shift menu items that don't fit into the secondary dropdown nav

const getSecondaryNav = computed<NavMainItem[]>(() =>
    concat(slice(getNavGrouped.value.primary, primaryLength.value), getNavGrouped.value.secondary)
)

const secondaryDropdown = ref(null)

const secondaryCurrent = computed(() => some(getSecondaryNav.value, nav => isCurrentRoute(nav?.route)))

const primaryLinkClasses = cva(
    [
        `inline-block`,
        `flex`,
        `gap-1`,
        `items-center`,
        `text-base`,
        `font-medium`,
        `rounded-full`,
        `!py-0`,
        `!px-3`,
        `!mx-2`,
        `h-9`,
        `leading-none`,
        `whitespace-nowrap`,
        `transition-colors`,
        `hover:no-underline`,
        `hover:!text-primary`,
        `hover:!bg-primary-50`
    ],
    {
        variants: {
            current: {
                true: [`text-primary`, `bg-primary-50`],
                false: [`text-primary-50`, `bg-transparent`]
            }
        }
    }
)

const secondaryProps = computed(() => ({
    autoHide: true,
    distance: 12,
    placement: `bottom-start`,
    theme: `main-nav-more`,
    popperClass: `horizon-ui`,
    triggers: [`click`],
    strategy: `fixed`
}))

const secondaryVisible = ref(false)

const setSecondaryVisible = (visible: boolean) => {
    secondaryVisible.value = visible
}

const secondaryClasses = cva(
    [
        `inline-block`,
        `flex`,
        `items-center`,
        `text-base`,
        `text-primary-50`,
        `bg-transparent`,
        `font-medium`,
        `rounded-full`,
        `!py-0`,
        `!px-3`,
        `h-9`,
        `leading-none`,
        `transition-colors`,
        `hover:!text-primary`,
        `hover:!bg-primary-50`
    ],
    {
        variants: {
            current: {
                true: [`!text-primary`, `!bg-primary-50`],
                false: [`text-primary-50`, `bg-transparent`]
            }
        }
    }
)

const secondaryLinksClasses = cva(
    [
        `flex`,
        `gap-1`,
        `text-primary`,
        `text-base`,
        `leading-5`,
        `font-medium`,
        `!px-2.5`,
        `!py-[9px]`,
        `hover:bg-primary/10`,
        `hover:no-underline`
    ],
    {
        variants: {
            current: {
                true: [`bg-primary/10`, `no-underline`]
            }
        }
    }
)

const upgradeBadgeClasses = `rounded-xl bg-purple-700 text-xs text-white !py-0.5 !px-2`
</script>

<template>
    <ul
        ref="mainMenu"
        class="flex flex-auto flex-nowrap items-center overflow-auto">
        <li
            v-for="primary in getPrimaryNav"
            :key="primary.route">
            <a
                :href="primary.route"
                :class="primaryLinkClasses({ current: isCurrentRoute(primary.route) })">
                {{ primary.label }}
                <span
                    v-if="primary.badge"
                    :class="upgradeBadgeClasses">
                    {{ primary.badge }}
                </span>
            </a>
        </li>
        <li
            v-if="getSecondaryNav.length"
            ref="secondaryDropdown"
            class="!mx-2">
            <VDropdown
                v-bind="secondaryProps"
                @apply-show="setSecondaryVisible(true)"
                @apply-hide="setSecondaryVisible(false)">
                <button
                    type="button"
                    :class="secondaryClasses({ current: secondaryVisible || secondaryCurrent })">
                    More

                    <BIcon
                        icon="caret-down"
                        class="!ml-2.5 !mt-[4px] h-[7px] w-auto" />
                </button>

                <template #popper="{ hide }">
                    <ul class="w-[180px]">
                        <template
                            v-for="secondary in getSecondaryNav"
                            :key="secondary.route">
                            <li v-if="secondary.label">
                                <a
                                    :href="secondary.route"
                                    :class="secondaryLinksClasses({ current: isCurrentRoute(secondary.route) })"
                                    @click="hide">
                                    <BIcon
                                        v-if="secondary.icon === `extensions`"
                                        icon="extensions"
                                        class="-!mt-0.5 !mr-1 inline-block h-4 w-4" />
                                    {{ secondary.label }}
                                    <span
                                        v-if="secondary.badge"
                                        :class="upgradeBadgeClasses">
                                        {{ secondary.badge }}
                                    </span>
                                </a>
                            </li>
                            <li
                                v-else
                                class="h-0 border-t border-solid border-t-secondary-50" />
                        </template>
                    </ul>
                </template>
            </VDropdown>
        </li>
        <!-- Duplicate menu and hide elements to calculate primary nav items -->
        <li
            v-for="primary in nav"
            :key="primary.route"
            ref="menuWidths"
            class="pointer-events-none absolute opacity-0">
            <a :class="primaryLinkClasses({ current: isCurrentRoute(primary.route) })">
                {{ primary.label }}
            </a>
        </li>
    </ul>
</template>

<style lang="scss">
.v-popper--theme-main-nav-more {
    .v-popper {
        &__wrapper {
            @apply overflow-hidden rounded bg-white shadow-dropdown;
        }

        &__arrow-container {
            @apply hidden;
        }
    }
}
</style>
